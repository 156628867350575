import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

export default defineNuxtPlugin(() => {
  dayjs.extend(utc)
  dayjs.extend(timezone)

  const guessedTz = dayjs.tz.guess()
  const fallbackTz = 'Europe/Prague'

  const validTz = guessedTz === 'Etc/Unknown' ? fallbackTz : guessedTz

  // Set timezone globally
  dayjs.tz.setDefault(validTz)
})
