import { defineNuxtPlugin } from '#app/nuxt'
import { LazyGlobal, LazySBButton, LazySBCard, LazySBCarousel, LazySBContactSection, LazySBFaq, LazySBFaqItem, LazySBFlex, LazySBGrid, LazySBGridColumn, LazySBHeadline, LazySBImage, LazySBIssuesList, LazySBPage, LazySBRibbon, LazySBSection, LazySBSeo, LazySBSpacer, LazySBTextContainer, LazyTeaser, LazyHelpers, LazyAppFooter, LazyAppNavbar, LazyAppSection, LazyAppCard, LazyAppLogo, LazyAppLogoSmall, LazyCommonLogo, LazyCommonMarkdownRenderer, LazyCommonTypographyStyledText, LazyCommonTypographyTruncated, LazyIssuesCalculator, LazyIssuesCard, LazyIssuesCtaBanner, LazyIssuesCtaCard, LazyIssuesDetail, LazyTypographySanitizedHTML, LazyTypographyStyledText, LazyTypographyTruncated } from '#components'
const lazyGlobalComponents = [
  ["Global", LazyGlobal],
["SBButton", LazySBButton],
["SBCard", LazySBCard],
["SBCarousel", LazySBCarousel],
["SBContactSection", LazySBContactSection],
["SBFaq", LazySBFaq],
["SBFaqItem", LazySBFaqItem],
["SBFlex", LazySBFlex],
["SBGrid", LazySBGrid],
["SBGridColumn", LazySBGridColumn],
["SBHeadline", LazySBHeadline],
["SBImage", LazySBImage],
["SBIssuesList", LazySBIssuesList],
["SBPage", LazySBPage],
["SBRibbon", LazySBRibbon],
["SBSection", LazySBSection],
["SBSeo", LazySBSeo],
["SBSpacer", LazySBSpacer],
["SBTextContainer", LazySBTextContainer],
["Teaser", LazyTeaser],
["Helpers", LazyHelpers],
["AppFooter", LazyAppFooter],
["AppNavbar", LazyAppNavbar],
["AppSection", LazyAppSection],
["AppCard", LazyAppCard],
["AppLogo", LazyAppLogo],
["AppLogoSmall", LazyAppLogoSmall],
["CommonLogo", LazyCommonLogo],
["CommonMarkdownRenderer", LazyCommonMarkdownRenderer],
["CommonTypographyStyledText", LazyCommonTypographyStyledText],
["CommonTypographyTruncated", LazyCommonTypographyTruncated],
["IssuesCalculator", LazyIssuesCalculator],
["IssuesCard", LazyIssuesCard],
["IssuesCtaBanner", LazyIssuesCtaBanner],
["IssuesCtaCard", LazyIssuesCtaCard],
["IssuesDetail", LazyIssuesDetail],
["TypographySanitizedHTML", LazyTypographySanitizedHTML],
["TypographyStyledText", LazyTypographyStyledText],
["TypographyTruncated", LazyTypographyTruncated],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
